<script lang="ts">
    import { onMount } from "svelte";
    import { saveLoginData, validateUserEmail } from "../service/auth/AuthService";
    import { pushSuccessToast } from "../service/ToastService";
    import { pageStore } from "../redux/stores";
    import EmptyContentPage from "../lib/EmptyContentPage.svelte";
    import { t } from 'svelte-i18n';

    let channel = new BroadcastChannel('email-validation-channel');
    let validationStatus = "loading";
    let token = "";
    let isLoading = true;

    onMount(() => {
        const params = new URLSearchParams(window.location.search);
        token = params.get("token") ?? ""

        if (token) {
            validateEmail(token);
        } else {
            validationStatus = "error-no-token";
            isLoading = false;
        }
    });

    async function validateEmail(token: string) {
        isLoading = true;
        try {
            await validateUserEmail(token).then((loginData) => {
                console.log(loginData);
                pushSuccessToast($t('email_validated'));
                channel.postMessage("close");
                saveLoginData(loginData);
                $pageStore = EmptyContentPage;
            }).catch(() => {
                validationStatus = "error";
            });
        } catch (error) {
            validationStatus = "error";
        } finally {
            isLoading = false;
        }
    }

    function retry() {
        validateEmail(token);
    }
</script>

<!-- Main Container -->
<div class="flex flex-col justify-center items-center h-screen bg-base-200 px-4 text-center">

    <!-- Loading State -->
    {#if isLoading}
        <div class="flex flex-col items-center">
            <span class="loading loading-spinner loading-lg text-primary mb-4"></span>
            <p class="text-lg font-semibold">{$t('email_validating')}</p>
        </div>

        <!-- Success Screen -->
    {:else if validationStatus === "success"}
        <div class="card shadow-lg compact bg-base-100 p-6">
            <img src="https://www.svgrepo.com/show/488925/error.svg" alt="Error" class="w-32 mx-auto mb-4 text-error" />
            <h1 class="text-3xl font-bold text-error mb-2">{$t('oops')}</h1>
            <p class="text-lg">{$t('no_token_error')}</p>
            <button class="btn btn-secondary mt-6" on:click={() => window.location.href='/request-new-email'}>{$t('request_new_email')}</button>
        </div>

        <!-- Error - No Token -->
    {:else if validationStatus === "error-no-token"}
        <div class="card shadow-lg compact bg-base-100 p-6">
            <img src="https://www.svgrepo.com/show/488925/error.svg" alt="Error" class="w-32 mx-auto mb-4" />
            <h1 class="text-3xl font-bold text-error mb-2">{$t('oops')}</h1>
            <p class="text-lg">{$t('no_token_error')}</p>
            <button class="btn btn-secondary mt-6" on:click={() => window.location.href='/request-new-email'}>{$t('request_new_email')}</button>
        </div>

        <!-- Error - Validation Failed -->
    {:else if validationStatus === "error"}
        <div class="card shadow-lg compact bg-base-100 p-6">
            <img src="https://www.svgrepo.com/show/488925/error.svg" alt="Error" class="w-32 mx-auto mb-4" />
            <h1 class="text-3xl font-bold text-error mb-2">{$t('validation_failed')}</h1>
            <p class="text-lg">{$t('validation_failed_message')}</p>
            <button class="btn btn-secondary mt-4" on:click={retry}>{$t('retry')}</button>
        </div>

    {/if}
</div>

<style>
    body {
        @apply bg-base-200; /* DaisyUI's background */
    }
</style>
