import type {ErrorResponse} from "../error/ErrorResponse";

export const sendOTP = async ({ email, token }: { email: string, token: string }): Promise<void | ErrorResponse> => {
    try {
        const response = await fetch(import.meta.env.VITE_POST_OTP, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, token }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            return {
                message: errorData.message,
                code: errorData.code,
            } as ErrorResponse;
        }

        console.log("OTP sent successfully");
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};
