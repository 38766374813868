<script lang="ts">
    import { breadcrumbs, pageStore } from "../../redux/stores";
    import { addNewRecruiter } from "../../service/RecruiterService";
    import Landing from "../content/Landing.svelte";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import type { CreateRecruiterExistingCompanyDto } from "../../service/dto/recruiter/CreateRecruiterExistingCompanyDto";
    import RecruiterManagement from "../../views/RecruiterManagement.svelte";
    import { validateRecruiterData } from "../../validators/AddRecruiterValidators";


    // Set up breadcrumbs for navigation
    breadcrumbs.set([
        { name: $t('Home'), component: Landing },
        { name: $t('UserPage'), component: RecruiterManagement },
        { name: $t('AddUser'), component: undefined }
    ]);

    let createRecruiterDto: CreateRecruiterExistingCompanyDto = {
        birthday: undefined,
        email: "",
        name: "",
        surname: "",
        isAdmin: false
    };

    async function handleSubmit() {
        const validationResult = validateRecruiterData(createRecruiterDto);

        if (!validationResult.isValid) {
            pushErrorToast($t(validationResult.message)); // Translate error message
            return;
        }

        console.log('Submitting:', createRecruiterDto);
        await addNewRecruiter(createRecruiterDto)
            .then(() => {
                pushSuccessToast($t('UserAdded')); // Translate success message
                pageStore.set(RecruiterManagement);
            })
            .catch(e => {
                console.error(e);
                pushErrorToast($t('ErrorAddingUser')); // Translate error message
            });
    }
</script>

<div class="container mx-auto p-4">
    <h1 class="text-3xl font-bold text-center mb-8">{$t('AddNewUser')}</h1> <!-- Translated title -->
    <form class="bg-base-200 p-6 rounded-lg shadow-lg" on:submit|preventDefault={handleSubmit}>
        <div class="form-control w-full mb-4">
            <label class="label" for="name">
                <span class="label-text">{$t('Name')}</span> <!-- Translated label -->
            </label>
            <input type="text" id="name" class="input input-bordered w-full" placeholder={$t('EnterName')}
                   bind:value={createRecruiterDto.name}/>
        </div>
        <div class="form-control w-full mb-4">
            <label class="label" for="surname">
                <span class="label-text">{$t('Surname')}</span> <!-- Translated label -->
            </label>
            <input type="text" id="surname" class="input input-bordered w-full" placeholder={$t('EnterSurname')}
                   bind:value={createRecruiterDto.surname}/>
        </div>
        <div class="form-control w-full mb-4">
            <label class="label" for="email">
                <span class="label-text">{$t('Email')}</span> <!-- Translated label -->
            </label>
            <input type="email" id="email" class="input input-bordered w-full" placeholder={$t('EnterEmail')}
                   bind:value={createRecruiterDto.email}/>
        </div>
        <div class="form-control w-full mb-4">
            <label class="label" for="birthday">
                <span class="label-text">{$t('Birthday')}</span> <!-- Translated label -->
            </label>
            <input type="date" id="birthday" class="input input-bordered w-full" bind:value={createRecruiterDto.birthday}/>
        </div>
        <!-- New Admin Checkbox Field -->
        <div class="form-control w-full mb-4">
            <label class="label cursor-pointer" for="isAdmin">
                <span class="label-text">{$t('Admin')}</span> <!-- Translated label -->
                <input type="checkbox" id="isAdmin" class="checkbox checkbox-primary ml-2" bind:checked={createRecruiterDto.isAdmin}/>
            </label>
        </div>
        <div class="form-control mt-6">
            <button type="submit" class="btn btn-primary">{$t('Submit')}</button> <!-- Translated button -->
        </div>
    </form>
</div>
