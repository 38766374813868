export enum Method {
    POST = "POST", GET = "GET"
}

export const makePostRequest = async <T>(url: string, body: any = undefined, isResponsePageable: boolean = false, contentType: string = "application/json",toJson: boolean = true): Promise<T> => {
    // Define headers with an index signature
    const headers: { [key: string]: string } = {
        "Authorization": "Bearer " + localStorage.getItem("token"),
    };

    // Add 'Content-Type' only if it's provided and body is not FormData
    if (contentType && !(body instanceof FormData)) {
        headers["Content-Type"] = contentType;
    }

    const response = await fetch(url, {
        method: 'post',
        body: body instanceof FormData ? body : JSON.stringify(body),
        headers: headers,
    });

    return handleResponse(isResponsePageable, response, toJson)
};


export const makePutRequest = async <T>(url: string, body: any = undefined, isResponsePageable: boolean = false, toJson: boolean = true): Promise<T> => {

    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },

    });

    return handleResponse(false, response, toJson)

}


export const makeDeleteRequest = async <T>(url: string, body: any = undefined, isResponsePageable: boolean = false, toJson: boolean = true): Promise<T> => {

    const response = await fetch(url, {
        method: 'delete',
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },

    });

    return handleResponse(false, response, toJson)

}


export const makeGetRequest = async <T>(url: string, body: any = undefined, isResponsePageable: boolean = false): Promise<T> => {

    const response = await fetch(url, {
        method: 'get',
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },
    });

    return handleResponse(isResponsePageable, response)

}

export const makePatchRequest = async <T>(url: string, body: any = undefined, isResponsePageable: boolean = false, toJson: boolean = true): Promise<T> => {

    const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        },
    });

    return handleResponse(isResponsePageable, response, toJson)

}

const handleResponse= async<T> (isResponsePageable:boolean, response: Response, toJson: boolean = true)=> {
    if (!response.ok) {
        handleDefaultError(response)
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json") && toJson) {
        if (isResponsePageable) {
            const data = await response.json();
            return data.content;
        } else {
            return await response.json();
        }
    } else if (contentType && contentType.includes("text/plain")) {
        const text = await response.text();
        return text as unknown as T;
    } else {
        throw new Error("Unsupported content type: " + contentType);
    }
}

const handleDefaultError = (response: Response) => {
    console.error(response.body)
    console.error(JSON.stringify(response))
    console.error("Error while making the request")
    throw new Error(`HTTP error! Status: ${response.status}`);
}