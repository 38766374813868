<script lang="ts">
    import { t } from 'svelte-i18n';
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import type { ApplicantDBTableData } from "../../model/applicant/ApplicantDBTableData";
    import { deleteApplicant } from "../../service/ApplicantService";
    import type { Page } from "../../model/Page";
    import {onMount} from "svelte";

    export let applicant: ApplicantDBTableData;
    export let applicants: Page<ApplicantDBTableData>;
    export let toogle: boolean = false;
    let input: HTMLInputElement;

    $: if (toogle) {
        document.getElementById('delete_applicant_modal').showModal()
        openModal();
    }

    // Function to open modal
    function openModal() {
        if (input) {
            input.click();
        }
    }

    function closeModal() {
        document.getElementById('delete_applicant_modal')?.close();
        toogle = false;
    }

    async function reject() {
        try {
            await deleteApplicant(applicant.id);
            applicants.content = applicants.content.filter(item => item.id !== applicant.id);
            applicants.totalElements = applicants.totalElements - 1;
            pushSuccessToast($t('ApplicantDeleted').replace("{name}", applicant.fullName));
        } catch {
            pushErrorToast($t('ErrorDeletingApplicant'));
        } finally {
            closeModal();
        }
    }

    // Ensure input is available when the component is mounted
    onMount(() => {
        console.log("Component mounted, input available:", input);
    });

</script>

<!-- Hidden input that triggers the modal -->
<input type="hidden" class="btn" onclick="delete_applicant_modal.showModal()" bind:this={input} />

<!-- Dialog modal for confirming applicant deletion -->
<dialog id="delete_applicant_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{$t('DeleteApplicantTitle').replace("{name}", applicant.fullName)}</h3>
        <p class="py-4">{$t('DeleteApplicantConfirmation').replace("{name}", applicant.fullName)}</p>
        <div class="modal-action">
            <button class="btn btn-primary" on:click={reject}>{$t('YesDeleteApplicant')}</button>
            <button class="btn btn-secondary" on:click={closeModal}>{$t('Cancel')}</button>
        </div>
    </div>
</dialog>