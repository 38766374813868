<script lang="ts">

    import {Country} from "../../model/location/Country";
    import {onMount} from "svelte";

    export let country: Country;
    let selectElement: HTMLSelectElement;
    let countries: Country[] = Object.values(Country) as Country[];

    onMount(() => {
        if (!country) {
            country = countries[0] as Country;
        }
        document.addEventListener('keydown', handleKeydown);
    });

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            selectElement.focus();
            selectElement.click();
        }
    }

    function formatCountryName(country: string): string {
        return country.replace(/_/g, ' ');
    }
</script>

<select bind:value={country} id="country" class="select select-bordered w-full text-lg font-semibold" bind:this={selectElement}>
    {#each countries as countryOption}
        <option value={countryOption}>{formatCountryName(countryOption)}</option>
    {/each}
</select>
