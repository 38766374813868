<script lang="ts">
    import { onMount } from "svelte";
    import { validateEmails } from "../../../validators/common/EmailValidators";
    import {userEmailStore} from "../../../redux/stores";
    import { t } from 'svelte-i18n';

    // Exported variables for binding
    export let email: string = "";
    export let isValid: boolean = false;
    let confirmEmail: string = "";

    // Validation result object
    export let validationResult: { isValid: boolean; message: string } = {
        isValid: false,
        message: "",
    };

    // Reference to the email input element
    let emailInput: HTMLInputElement;

    // Function to handle email validation
    function handleEmailValidation() {
        console.log("Validating emails:", email, confirmEmail); // Debugging log
        validationResult = validateEmails(email, confirmEmail);
        isValid = validationResult.isValid;
        console.log("Validation result:", validationResult); // Debugging log
        $userEmailStore = email
    }

    // Watch email and confirmEmail for changes using a reactive statement
    $: {
        handleEmailValidation();
    }

    onMount(() => {
        isValid = false;
        console.log("Component mounted, initial isValid set to false."); // Debugging log

        // Focus the email input field
        if (emailInput) {
            emailInput.focus();
        }
    });
</script>

<div class="flex flex-col gap-8 px-6 md:px-12">
    <!-- Row for email and confirm email -->
    <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <!-- Email Input -->
            <label class="label" for="email">
                <span class="label-text text-xl">{$t("EmailAddress")}</span>
            </label>
            <input
                    type="email"
                    id="email"
                    class="input input-bordered w-full text-xl h-16"
                    bind:value={email}
                    bind:this={emailInput}
            on:input={() => handleEmailValidation()}
            />
        </div>
        <div class="w-full md:w-1/2 px-4">
            <!-- Confirm Email Input -->
            <label class="label" for="confirmEmail">
                <span class="label-text text-xl">{$t("ConfirmEmailAddress")}</span>
            </label>
            <input
                    type="email"
                    id="confirmEmail"
                    class="input input-bordered w-full text-xl h-16"
                    bind:value={confirmEmail}
                    on:input={() => handleEmailValidation()}
                    on:paste|preventDefault
            />
        </div>
    </div>

    <!-- Display validation message using DaisyUI alerts -->
    {#if email !== "" || confirmEmail !== ""}
        {#if validationResult.message}
            <div class={`alert ${isValid ? 'alert-success' : 'alert-error'} shadow-lg`}>
                <div>
                    <span>{validationResult.message}</span>
                </div>
            </div>
        {/if}
    {/if}
</div>
