<script lang="ts">
    import { onMount } from 'svelte';
    import { Router, Route } from 'svelte-routing';
    import { setupI18n } from './i18n';
    import * as Sentry from "@sentry/svelte";

    import EmptyContentPage from "./lib/EmptyContentPage.svelte";
    import Login from "./views/Login.svelte";
    import ProtectedRoute from "./ProtectedRoute.svelte";
    import LogoutModal from "./lib/content/modals/LogoutModal.svelte";
    import { SvelteToast } from '@zerodevx/svelte-toast';
    import Register from "./lib/auth/Register.svelte";
    import GoogleCallbackScreen from "./lib/auth/GoogleCallbackScreen.svelte";
    import ValidateUserEmail from "./views/ValidateUserEmail.svelte";
    import EmailValidationPrompt from "./lib/auth/register/EmailValidationPrompt.svelte";

    let i18nReady = false;

    onMount(async () => {
        await setupI18n();
        i18nReady = true;
    });

    const app = new SvelteToast({
        target: document.body,
        props: {
            options: {
                // Optionally set default options here
            }
        }
    });

    if (import.meta.env.PROD) {
        Sentry.init({
            dsn: "https://32ed3f17a0fb86fed1aa873688e330c4@o4507903424200704.ingest.us.sentry.io/4507903426166784",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost",  new RegExp(`^${import.meta.env.VITE_BASE_URL}/api`)],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }


</script>
{#if i18nReady}
    <Router>
        <ProtectedRoute path="/" component={EmptyContentPage} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/googleCallback" component={GoogleCallbackScreen} />
        <Route path="/validate-email" component={ValidateUserEmail} />
        <Route path="/email-prompt" component={EmailValidationPrompt} />
    </Router>
    <LogoutModal/>
{:else}
    <p>Loading...</p>
{/if}
