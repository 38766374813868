<script lang="ts">
    import { t } from 'svelte-i18n';
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import { pageStore } from "../../redux/stores";
    import OpenPositions from "../content/PositionsPage.svelte";

    export let positionData: JobPostGeneratorDto;
    export let positionId: string;
    export let showPositionData = true;

    let receiveApplicantsBy = "By email";
    let copied = false;

    function handleFinish() {
        console.log("Submission complete");
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(positionId + "@job-listings.astrovel.net").then(() => {
            copied = true;
            setTimeout(() => {
                copied = false;
            }, 2000);
        });
    }
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('ApplicantCollection')}</h2>
        {#if showPositionData}
            <div class="mb-6">
                <p><strong>{$t('JobTitle')}:</strong> {positionData.jobTitle}</p>
                <p><strong>{$t('WorkplaceType')}:</strong> {positionData.workplaceType}</p>
                <p><strong>{$t('Country')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
                <p><strong>{$t('JobType')}:</strong> {positionData.employmentType}</p>
                <p><strong>{$t('RoleDescription')}:</strong> {positionData.jobDescription}</p>
                <p><strong>{$t('Skills')}:</strong> {positionData.skills.join(', ')}</p>
                <p><strong>{$t('ScreeningQuestions')}:</strong> {positionData.screeningQuestions.map(q => q.text).join(', ')}</p>
            </div>
        {/if}
        <div class="mb-6">
            <p class="text-center">{$t('Instruction')}</p>
            <p class="text-center text-error font-bold">{$t('ImportantNotice')}</p>
        </div>
        <div class="form-control mb-4">
            <label class="label">
                <span class="label-text">{$t('ReceiveApplicants')}</span>
            </label>
            <select bind:value={receiveApplicantsBy} class="select select-bordered w-full" disabled>
                <option>By email</option>
                <!-- Add other options if necessary -->
            </select>
        </div>
        <div class="form-control mb-4">
            <label class="label">
                <span class="label-text">{$t('EmailAddress')}</span>
            </label>
            <div class="relative">
                <input type="text" value={positionId + "@job-listings.astrovel.net"} class="input input-bordered w-full pr-20" readonly />
                <button on:click={copyToClipboard}
                        class="btn btn-secondary absolute right-0 top-0 h-full rounded-l-none">{$t('Copy')}</button>
            </div>
        </div>
        {#if copied}
            <p class="text-success text-center">{$t('EmailCopied')}</p>
        {/if}
        <div class="mb-6">
            <p class="text-center">{$t('CopyEmailInstruction')}</p>
            <p class="text-center">{$t('QuestionsSupport')}</p>
        </div>
        {#if showPositionData}
            <button on:click={() => pageStore.set(OpenPositions)} class="btn btn-primary w-full">{$t('Finish')}</button>
        {/if}
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
