<script lang="ts">
    import type {ApplicantDBTableData} from "../../../model/applicant/ApplicantDBTableData";
    import {breadcrumbs, loadPositionDataInModern, pageStore, selectedPositionId} from "../../../redux/stores";
    import PositionDetails from "../../../views/PositionDetails.svelte";
    import DeleteApplicantModal from "../../Modals/DeleteApplicantModal.svelte";
    import {type Page, PageUtils} from "../../../model/Page";
    import { t } from 'svelte-i18n';
    import {
        cachedApplicantsForPosition,
        getApplicantById
    } from "../../../service/ApplicantService";
    import Modern from "../Modern.svelte";
    import Landing from "../Landing.svelte";
    import ApplicantsDatabase from "../ApplicantsDatabase.svelte";

    export let applicant: ApplicantDBTableData;
    export let applicants: Page<ApplicantDBTableData>
    let showDeleteModal = false

    function formatDate(dateString: string): string {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    function goToPosition() {
        pageStore.set(PositionDetails);
        selectedPositionId.set(applicant.positionApplied.id);
    }

    async function goToApplicant() {
        $cachedApplicantsForPosition = PageUtils.pageOf([await getApplicantById(applicant.id)])
        $pageStore = Modern
        $loadPositionDataInModern = false
        breadcrumbs.set([{ name: 'Home', component: Landing },
            { name: $t('ApplicantsDatabase'), component: ApplicantsDatabase },
            { name: applicant.fullName, component: null }, ]);

    }

    function onDelete(){
        showDeleteModal = true
    }


</script>

<!-- Recruiter table row -->
<tr>
    <td>{applicant.fullName}</td>
    <td>{applicant.email}</td>
    <td>{applicant.lastRole}</td>
    <td>{applicant.lastCompany}</td>
    <td><a class="link" on:click={goToPosition} href="#">{applicant.positionApplied.name}</a></td>
    <td>{applicant.yoe}</td>
    <!-- Recruiter account creation date -->
    <td>{formatDate(applicant.createdAt)}</td>
    <td class="flex flex-wrap space-x-2 items-center">
        <button class="btn btn-error" on:click={onDelete}>{$t('Delete')}</button>
        <button class="btn btn-primary" on:click={goToApplicant}>{$t('See Full Profile')}</button>
            <!-- Dropdown for more actions
            <div class="dropdown">
                <button tabindex="0" class="btn btn-outline btn-secondary dropdown-toggle">
                    <i class="fas fa-ellipsis-h"></i> Apply to new position
                </button>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-60">
                    <li class="menu-item">
                        <a  class="flex items-center space-x-2">
                            <i class="fas fa-user-cog"></i>
                            <span>Holaa</span>
                        </a>
                    </li>
                </ul>
            </div> -->
    </td>
</tr>

<DeleteApplicantModal applicant={applicant} bind:toogle={showDeleteModal} bind:applicants={applicants}/>

<style>
    .dropdown-content {
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        max-height: 300px;
        overflow-y: auto;
        z-index: 1000;
    }

    .menu-item {
        padding: 12px 16px;
        transition: background-color 0.2s ease, color 0.2s ease;
        cursor: pointer;
    }

    .menu-item:hover {
        background-color: #f4f4f9;
        color: #0056b3;
    }

    .separator {
        border-top: 1px solid #e0e0e0;
        margin-top: 4px;
    }

    .dropdown-content li a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #333;
        font-weight: 500;
    }

    .dropdown-content li a:hover {
        color: #0056b3;
    }

    .dropdown-content i {
        margin-right: 8px;
        font-size: 16px;
    }

    .btn-outline.btn-secondary {
        padding: 8px 16px;
        font-weight: bold;
        transition: background-color 0.2s ease, border-color 0.2s ease;
    }

    .btn-outline.btn-secondary:hover {
        background-color: #e7f1ff;
    }
</style>