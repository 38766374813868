<script lang="ts">
    import {breadcrumbs, pageStore, userInfo} from "../redux/stores";
    import AddRecruiterPage from "../lib/pages/AddRecruiterPage.svelte";
    import {getRecruiters} from "../service/RecruiterService";
    import Landing from "../lib/content/Landing.svelte";
    import RecruiterManagmentTable from "../lib/RecruiterManagment/RecruiterManagmentTable.svelte";
    import { t } from "svelte-i18n";

    breadcrumbs.set([{ name: 'Home', component: Landing }, { name: 'Users Page', component: undefined }]);

    let user = $userInfo;
    let showAddNewRecrutier: boolean;

    if(user){
        showAddNewRecrutier = user.isAdmin === true || user.isOwner === true;
    }

    function addNewRecruiter(): void {
        console.log("Adding a new recruiter...");
        pageStore.set(AddRecruiterPage);
    }
</script>

{#await getRecruiters()}
    <p>...waiting</p>
{:then recruiters}
    <div class="recruiters-page bg-base-100 p-8">
        <div class="flex justify-between items-center mb-6">
            <h1 class="text-4xl font-bold">{$t("Users")}</h1>
                {#if showAddNewRecrutier}
                <button class="btn btn-primary" on:click={addNewRecruiter}>
                    {$t("AddNewUser")}
                </button>
                {/if}
        </div>
        <RecruiterManagmentTable {recruiters} />
    </div>
{:catch error}
    <p style="color: red">{error.message}</p>
{/await}

<style>
    .avatar img {
        width: 100%;
        height: 100%;
    }
</style>
